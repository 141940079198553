import * as React from 'react';

type Props = Omit<React.ComponentPropsWithoutRef<'svg'>, 'xmlns' | 'viewBox' | 'className'>;

// https://thenounproject.com/term/megaphone/1399378/
// Created by Jony from the Noun Project
const Icon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="icon" {...props}>
    <path d="M77.2,7c-3.5,0-6.4,2.4-7.1,5.8L28.7,27.7H14.8c-4.4,0-8,3.6-8,8v22.8c0,0.2,0,0.4,0.1,0.6l0,0   l8.4,30.4c0.2,0.8,0.9,1.4,1.8,1.5h16.4c1.1,0,2-0.9,2-2c0-0.2,0-0.3-0.1-0.5l-4.6-17.7L43,65l27.2,8.5c0.6,3.9,4.3,6.6,8.3,6   c3.6-0.6,6.2-3.8,6-7.5V61.2l4.3-0.8c3.8-0.7,6.5-4,6.5-7.9V34.6c0-3.9-2.7-7.2-6.5-7.9L84.4,26V14.3c0.1-4-3.1-7.2-7.1-7.3   C77.3,7,77.2,7,77.2,7z M31.1,31L70,17.1v52.1L31.1,57.1L31.1,31z M10.8,35.7c0-2.2,1.8-4,4-4h12.3v24.8H10.8V35.7z M27.6,67.9   c-0.9,0.4-1.3,1.4-1.1,2.3L30.9,87H18.7l-7.3-26.5h17.3l8.6,2.7L27.6,67.9z M80.4,71.9c0,1.8-1.4,3.2-3.2,3.2   c-1.8,0-3.2-1.4-3.2-3.2V14.3c0-1.8,1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2V71.9z M87.9,30.7c1.9,0.3,3.3,2,3.3,3.9v18   c0,1.9-1.4,3.6-3.3,3.9l-3.5,0.7V30L87.9,30.7z" />
  </svg>
);

export default Icon;
